/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '@fortawesome/fontawesome-svg-core/styles.css'; /* avoid biggest icons on first load */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');

.slide-w-auto.swiper-slide {
    @apply w-auto mr-3;
}

* {
    font-family: 'Baloo Bhai 2', sans-serif;
}

*:focus {
    outline: none;
}

h1 {
    font-size: 2.5rem;
    font-weight: bold;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
    font-weight: bold;
}

h4 {
    font-size: 1.2rem;
}

.font-size-x3 {
    font-size: 3rem;
}

.font-size-x5 {
    font-size: 5rem;
}

.font-size-x10 {
    font-size: 10rem;
}

.font-size-x15 {
    font-size: 15rem;
}

.item-interactive.ion-invalid {
    --highlight-background: #ffff0000;
}

.item-interactive.ion-valid {
    --highlight-background: #ffff0000;
}

// Remove autofill focus
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none !important;
    -webkit-text-fill-color: inherit !important;

    /* -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset; */
    transition: background-color 5000s ease-in-out 0s;
}

// ------------------------------  INPUT WITH ICON  -----------------------------
.password {
    position: relative;
    display: inline-block;
    width: 100%;
}

.password input {
    text-indent: 32px;
}

.password .password-icon {
    cursor: pointer;
    color: gray;
    position: absolute;
    top: 45px;
    left: auto;
    right: 20px;
    z-index: 1000;
}
// ------------------------------  INPUT WITH ICON  -----------------------------

ion-content {
    --background: var(--ion-color-lightgray);
}

ion-button {
    font-weight: bold;
    text-transform: none;

    --box-shadow: none;
}

ion-button.button-mini {
    ion-icon {
        height: 15px;
    }
}

.ads-popover {
    --width: 1080px;
    --height: 667px;
    --border-radius: 20px;
}

.medium-popover {
    --width: 50%;
}

.large-popover {
    --width: 80%;
}

.full-popover {
    --width: 50%;
    --height: 80%;
    --border-radius: 12px;

    .paw {
        ion-content::part(background) {
            background-size: cover;
            opacity: 0.2;

            --background: url('assets/img/patte.png');
        }
    }
}

.input-box {
    border-color: #403e39;
    border-width: thin;
    border-style: solid;
    border-radius: 3px;
}

.input-box:has(.ng-valid) {
    border-color: green;
}

.ion-flex {
    display: flex;
}

.ion-flex-col {
    flex-direction: column;
}

.ion-h-75 {
    height: 75%;
}

.ion-h-100 {
    height: 100%;
}

.ion-w-100 {
    width: 100%;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

ion-modal.filter-modal {
    --border-radius: 14px;
}

.position-relative {
    position: relative;
}

.ion-gap-8px {
    gap: 8px;
}

.ion-gap-16px {
    gap: 16px;
}

.ion-gap-24px {
    gap: 24px;
}

.ion-gap-32px {
    gap: 32px;
}

.ion-mb-8px {
    margin-bottom: 8px;
}

.ion-mb-16px {
    margin-bottom: 16px;
}

.ion-mb-24px {
    margin-bottom: 24px;
}
// google dropdown style

.pac-container {
    @apply bg-white rounded-2xl outline-none border-none mt-2 shadow-light-primary-20;
}

// hide google logo
.pac-container::after {
    background-image: none !important;
    height: 0;
    background-color: var(--ion-color-light);
}

.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url('https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png');
    background-size: 34px;
}

.pac-item {
    padding: 8px;
    border: none;
}

*[ng-reflect-router-link]:focus {
    outline: none;
}

div:focus {
    outline: 0;
}

ion-popover {
    &::part(content) {
        @apply rounded-xl shadow-light-primary-30;
    }
}

ion-popover.categories-popover {
    &::part(content) {
        @apply rounded-xl shadow-light-primary-30 w-[550px] mt-3 max-h-48;
    }
}

ion-popover.popover-menu-small {
    --width: 200px;
}

.legal-use-modal::part(content) {
    border-radius: 20px;
}

.logout-modal::part(content) {
    width: 10%;
    height: 10%;
    border-radius: 5px;
}

.zoom-modal::part(content) {
    border-radius: 20px;
    width: 90%;
    height: 90%;
}

.pac-item-query {
    color: var(--ion-color-dark);
}

// Toast positioning
.md .toast {
    transform: translateY(-56px) !important;
}

.ios .toast {
    transform: translateY(-50px) !important;
}

ion-fab-button[title] {
    position: relative;
}

ion-fab-button[title]::after {
    position: absolute;
    content: attr(title);
    z-index: 1;
    right: 60px;
    bottom: 10px;
    background-color: var(--ion-color-primary);
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
}

ion-segment.segment-desktop {
    border-radius: 50px;

    ion-segment-button {
        --background-checked: var(--ion-color-primary);
        --color-checked: var(--ion-color-primary-contrast);
        --indicator-color: transparent;
    }
}

.bottom-spacer {
    margin-bottom: 100px;
}
